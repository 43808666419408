import moment from 'moment';
import { default as momentHijri } from 'moment-hijri';
import {
  DATE_MOMENT_HIJRI_DASH,
  DATE_MOMENT_HIJRI_SLASH,
  DATE_MOMENT_ISO,
  DATE_TIME_ISO_PAYLOAD,
  DATE_TIME_UI,
  DATE_UI,
  TIME_24_FORMAT,
} from '../constant/date-formats';
import { MomentLocaleEnum } from '../enums/moment-locale.enum';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { differenceInSeconds, intervalToDuration, parseISO } from 'date-fns';

export function isValidDatePayload(date: string): boolean {
  return moment(date, DATE_MOMENT_ISO, true).isValid();
}

export function isValidDateIso(date: string): boolean {
  return moment(date, moment.ISO_8601, true).isValid();
}

export function getCurrentDateOnly() {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return currentDate;
}

export function getFormattedDate(date: string | Date): Date | string {
  if (moment(date, DATE_UI).isValid()) {
    return moment(date, DATE_UI).locale(MomentLocaleEnum.en).toDate();
  }
  return date
    ? moment(new Date(date)).locale(MomentLocaleEnum.en).toDate()
    : '';
}

export function formatDateToUi(
  date: Date | string,
  resultFormat = DATE_UI
): string {
  const fromFormat = date instanceof Date ? undefined : DATE_MOMENT_ISO;
  return moment(date, fromFormat)
    .locale(MomentLocaleEnum.en)
    .format(resultFormat);
}

export function formatDateTimeToUi(dateTime: Date | string) {
  return moment(dateTime instanceof Date ? dateTime : new Date(dateTime))
    .locale(MomentLocaleEnum.en)
    .format(DATE_TIME_UI);
}

export function getDatePayload(
  date: string | Date,
  resultFormat = DATE_MOMENT_ISO
): string {
  if (!date) return date;
  let dateValue = '';
  if (!!date) {
    dateValue = moment(getFormattedDate(date))
      .locale(MomentLocaleEnum.en)
      .format(resultFormat);
  }
  return dateValue;
}

export function getIsoDateTimePayload(
  serverDateFormat: string,
  {
    second,
    minute,
    hour,
  }: { second?: number; minute?: number; hour?: number } = { second: 0 }
) {
  const dateTime = new Date(serverDateFormat);
  return moment(dateTime)
    .set({
      second,

      ...(minute !== undefined && { minute }),
      ...(hour !== undefined && { hour }),
    })
    .locale(MomentLocaleEnum.en)
    .format(DATE_TIME_ISO_PAYLOAD);
}

export function getTimePayload(time: string) {
  return time + ':00';
}

export function get_24_Time(stringDate: string) {
  return moment(stringDate).locale(MomentLocaleEnum.en).format(TIME_24_FORMAT);
}

export function addDaysToDate(date: Date | string, daysCount: number): Date {
  date = date instanceof Date ? date : new Date(date);
  return moment(date)
    .locale(MomentLocaleEnum.en)
    .add(daysCount, 'days')
    .toDate();
}

export function addMonthsToDate(
  date: Date | string,
  monthsCount: number
): Date {
  date = date instanceof Date ? date : new Date(date);
  return moment(date)
    .locale(MomentLocaleEnum.en)
    .add(monthsCount, 'months')
    .toDate();
}

export function addYearsToDate(date: Date | string, yearsCount: number): Date {
  date = date instanceof Date ? date : new Date(date);
  return moment(date)
    .locale(MomentLocaleEnum.en)
    .add(yearsCount, 'years')
    .toDate();
}

export function getDiffInSeconds(
  startDate: Date | number,
  endDate: Date | number
): number {
  return differenceInSeconds(startDate, endDate);
}

export function isDateAfter(
  firstDate: string | Date,
  secondDate: string | Date
): boolean {
  return moment(firstDate).locale(MomentLocaleEnum.en).isAfter(secondDate);
}

export function getYearFromDate(
  date: string | null,
  format = DATE_MOMENT_ISO
): number | null {
  if (!date) return null;
  return moment(new Date(date), format).locale(MomentLocaleEnum.en).year();
}

export function isDatesEqual(
  firstDate: string | Date,
  secondDate: string | Date
): boolean {
  return (
    moment(firstDate).format(DATE_MOMENT_ISO) ===
    moment(secondDate).format(DATE_MOMENT_ISO)
  );
}

// This function is used in injection context
export function getDistanceBetween() {
  const translateService = inject(TranslateService);

  return (startDate: string, endDate: string): string | null => {
    if (!startDate || !endDate) return '';
    const { years, months, days, hours, minutes } = intervalToDuration({
      start: parseISO(startDate),
      end: parseISO(endDate),
    });
    const yearLabel = !!years
      ? `${years} ${translateService.instant('shared.year')}`
      : '';
    const monthLabel = !!months
      ? `${months} ${translateService.instant('shared.month')}`
      : '';
    const dayLabel = !!days
      ? `${days} ${translateService.instant('shared.day')}`
      : '';
    const hourLabel = !!hours
      ? `${hours} ${translateService.instant('shared.hour')}`
      : '';
    const minuteLabel = !!minutes
      ? `${minutes} ${translateService.instant('shared.minute')}`
      : '';
    const labelListFiltered = [
      yearLabel,
      monthLabel,
      dayLabel,
      hourLabel,
      minuteLabel,
    ].filter((val) => !!val);

    if (!labelListFiltered.length) {
      return null;
    }

    return labelListFiltered.join(' + ');
  };
}
/** * momentHijri Utils */
export function formatHijriDate(
  hijriDate: string,
  resultFormat = DATE_MOMENT_HIJRI_SLASH
): string {
  return momentHijri(hijriDate, DATE_MOMENT_HIJRI_DASH)
    .locale(MomentLocaleEnum.en)
    .format(resultFormat);
}

export function getHijriDatePayload(
  hijriDate: string,
  resultFormat = DATE_MOMENT_HIJRI_DASH
): string {
  return formatHijriDate(hijriDate, resultFormat);
}

export function convertGeorgianToHijri(
  georgianDate: Date | string,
  resultFormat = DATE_MOMENT_HIJRI_SLASH
): string {
  if (!(georgianDate instanceof Date)) {
    georgianDate = new Date(georgianDate);
  }
  if (!georgianDate) return '';

  return momentHijri(georgianDate)
    .locale(MomentLocaleEnum.en)
    .format(resultFormat);
}

export function isHijriDate(date: string | null) {
  const yearOfBirth = getYearFromDate(date, DATE_MOMENT_ISO);
  return !!yearOfBirth && yearOfBirth < 1500;
}

export function convertHijriToGeorgian(date: string): string {
  return momentHijri(date, DATE_MOMENT_HIJRI_DASH)
    .locale(MomentLocaleEnum.en)
    .format(DATE_MOMENT_ISO);
}
